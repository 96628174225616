.userInfoButton {
  background: transparent;
  border: 0;
  display: flex;
  align-items: center;

  box-sizing: border-box;
}
.userInfoButton .popoverAnchor::after {
  content: "▼";
  font-size: 8px;
  display: inline-block;
  padding-left: 2px;
  transform: translateY(1px);
}
.userInfoButton:hover {
  color: #f1a361;
}
.userInfoPopover {
  inset-block-start: anchor(self-end);
  inset-inline-end: anchor(end);
  margin: 0;
  padding: 0;
  border: 0;

  display: flex;
  flex-direction: column;
  min-width: 10rem;

  box-shadow: 0 0 12px rgb(171 171 171 / 37%);
}
.userInfoPopover > button {
  background: transparent;
  border: none;
  text-align: start;
}
.userInfoPopover > button:hover {
  background: #6a4637;
  color: white;
}
