.notifications {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
}
.notification {
  border-radius: 4px;
  overflow: hidden;
}
.notification > button {
  border: none;
  background: none;
  color: inherit;
}
.passkeynag > button {
  background: #6a4637;
  color: white;
  border: none;
  padding: 0.5rem;
}
.errorNotification {
  background-color: #c11000;
  padding: 0.3rem;
  color: white;
}
.successNotification {
  background-color: #2ca600;
  padding: 0.3rem;
  color: white;
}
